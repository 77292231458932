import React, { useEffect } from "react";

function useIframeResizer(containerRef: React.MutableRefObject<any>) {
  useEffect(() => {
    const sendHeight = () => {
      if (containerRef?.current) {
        // console.log(containerRef.current.scrollHeight);
        const height = containerRef.current.scrollHeight;
        window.parent.postMessage({ height }, "*");
      }
    };

    const resizeObserver = new MutationObserver(sendHeight);
    resizeObserver.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true,
    });

    window.addEventListener("resize", sendHeight);
    window.addEventListener("load", sendHeight);

    sendHeight();

    // Cleanup
    return () => {
      window.removeEventListener("resize", sendHeight);
      window.removeEventListener("load", sendHeight);
      resizeObserver.disconnect();
    };
  }, [containerRef]);
}

export default useIframeResizer;
