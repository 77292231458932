import { Flex, notification, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStripeProducts } from "src/constants/types";
import { convertToNumber } from "src/utils/helper-functions";
import RequestAppAction from "src/store/slices/appActions";
import { getStripeProductsDbData, getStripeProductsDbLoading } from "src/store/selectors/features/stripeProductsDb";
import PlanDetail from "../PlanDetail";
import Header from "../Header";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IStep1 {
  setSelectedPlan?: (plan: any) => void;
  nextStep?: () => void;
  isPlanPage?: boolean;
  onMoveToPlan?: (n: number) => void;
}

const Plans = ({ setSelectedPlan, nextStep, isPlanPage = false, onMoveToPlan }: IStep1) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const stripeProductsDbLoading: boolean = useSelector(getStripeProductsDbLoading);
  const stripeProductsDbData: any = useSelector(getStripeProductsDbData);

  const [deepLinkPlanNum, setDeepLinkPlanNum] = useState<number | null>(null);
  const [plans, setPlans] = useState<IStripeProducts[]>([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState<boolean>(true);

  useEffect(() => {
    if (!searchParams) return;

    const planNumStr = searchParams.get("plan");

    if (planNumStr && typeof planNumStr === "string") {
      try {
        const planNum = convertToNumber(planNumStr);
        if (planNum > 0) {
          setDeepLinkPlanNum(planNum);
        }
      } catch (error) {
        console.error("Error reading the plan number. Start from the first step.");
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isLoadingPlans || stripeProductsDbLoading) return;

    if (Array.isArray(stripeProductsDbData) && stripeProductsDbData.length > 0) {
      setPlans(sortPlans(stripeProductsDbData));
      setIsLoadingPlans(false);
    } else {
      dispatch(RequestAppAction.fetchStripeProductsDb({
        cbSuccess: (res: any) => {
          setPlans(sortPlans(res));
          setIsLoadingPlans(false);
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
          setIsLoadingPlans(false);
        },
      }));
    }
  }, [stripeProductsDbLoading, stripeProductsDbData]);

  useEffect(() => {
    if (
      !isPlanPage &&
      !isLoadingPlans &&
      Array.isArray(plans) &&
      plans.length > 0 && 
      deepLinkPlanNum &&
      deepLinkPlanNum > 0 &&
      deepLinkPlanNum <= plans.length
    ) {
      onSelectPlan(plans[deepLinkPlanNum - 1]);
    }
  }, [isLoadingPlans, deepLinkPlanNum]);

  const sortPlans = (plans: any[]) => {
    if (Array.isArray(plans) && plans.length > 0) {
      const sortedPlans = plans.slice().sort((a: any, b: any) => {
        if (a.tier === undefined && b.tier === undefined) return 0;
        if (a.tier === undefined) return 1;
        if (b.tier === undefined) return -1;
        return a.tier - b.tier;
      });
      if (sortedPlans && sortedPlans.length > 0) {
        return sortedPlans;
      } else {
        return plans;
      }
    } else {
      return [];
    }
  };

  const onSelectPlan = (plan: any, planIndex?: number) => {
    if (setSelectedPlan) {
      setSelectedPlan(plan);
    }

    if (isPlanPage && onMoveToPlan) {
      onMoveToPlan(planIndex !== null && planIndex !== undefined ? planIndex + 1 : 0);
    } else if (!isPlanPage && nextStep) {
      nextStep();
    }
  };

  const tapMoreProduct = {
    stripeProductName: "Tap More",
    monthlyRate: "",
    messageRate: "",
    messageThreshold: 0,
  }

  return (
    <Flex gap={25} vertical className={styles.step0}>
      <Header step={0} hasLogo={false} breadcrumb={t("subscribeStep0.breadcrumb")} />
      <Text
        style={{ textAlign: "center" }}
        className="font-size-16 font-weight-500"
      >
        {t("subscribeStep0.selectPlan")}
      </Text>
      <Spin spinning={isLoadingPlans}>
        <Row gutter={[16, 24]} justify="center" style={{ width: "100%" }}>
          {plans && plans.map((product: any, index: number) => (
            <PlanDetail
              key={`plan-${index}`}
              planData={product}
              onSelectPlan={onSelectPlan}
              planIndex={index}
            />
          ))}
          <PlanDetail
            planIndex={Array.isArray(plans) && plans.length > 0 ? plans.length : 0}
            planData={tapMoreProduct}
            onContact={() =>
              window.open('https://textifynow.com/contact-us/', '_blank')
            }
          />
        </Row>
        <div className={styles.notes}>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note1") }} />
          </Text>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note2") }} />
          </Text>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note3") }} />
          </Text>
        </div>
      </Spin>
    </Flex>
  );
};

export default Plans;
