import { createSelector } from "reselect";

const stripeProductsDbSelector = (state: TReduxState) => state.features.stripeProductDb;

export const getBaseUrl = createSelector(stripeProductsDbSelector, (app) => app.baseUrl);

export const getStripeProductsDbData = createSelector(
  stripeProductsDbSelector,
  (app) => app.apiStatus.data
);

export const getStripeProductsDbState = createSelector(stripeProductsDbSelector, (app) => app.stripeProductDbState);

export const getStripeProductsDbLoading = createSelector(
  getStripeProductsDbState,
  (states) => states?.isLoading
);
