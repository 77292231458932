import React, { useRef } from "react";
import { Flex, Space } from "antd";
import useIframeResizer from "src/hooks/useIframeResizer";
import Plans from "src/components/subscribe/steps/Plans";
import styles from "../subscribe.module.scss";

const PlanPage: React.FC = () => {
  const containerRef = useRef(null);

  useIframeResizer(containerRef);

  const onMoveToPlan = (n?: number) => {
    if (n !== null && n !== undefined && n > 0 && window) {
      window.parent.location.href = `https://textifynow.com/subscribe?plan=${n}`;
    }
  };

  return (
    <Flex
      ref={containerRef}
      justify="start"
      align="center"
      gap={10}
      vertical
      style={{ width: "100%", overflowY: "auto" }}
      className={styles.subscribe_form_container}
    >
      <Space
        direction="vertical"
        style={{ width: "100%", maxWidth: "1440px" }}
      >
        <Plans isPlanPage={true} onMoveToPlan={onMoveToPlan}  />
      </Space>
    </Flex>
  );
};

export default PlanPage;
